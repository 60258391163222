import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Card,
  Divider,
  CardContent,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectFactoryData } from "../../selectors/factory.selector";
import {
  selectAuditData,
  selectAuditPublishedStatus,
} from "../../selectors/audit.selector";
import { getFactoryById } from "../../reducers/factory.reducer";
import { useParams } from "react-router-dom";
import moment from "moment";
import { selectEditModeState } from "../../selectors/misc.selector";
import EditIcon from "@mui/icons-material/Edit";
import { AuditStyles, ComponentStyles } from "../../styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const AuditInformation = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const publishedStatus = useSelector(selectAuditPublishedStatus);

  const selectedAuditData = useSelector(selectAuditData);
  const selectedFactoryData = useSelector(selectFactoryData);
  const [factoryData, setFactoryData] = useState(selectedFactoryData);
  const [isEditingInternal, setIsEditingInternal] = useState(false);

  const editMode = useSelector(selectEditModeState);

  const toggleEditInternal = () => {
    setIsEditingInternal(!isEditingInternal);
  };


  return (
    <Box sx={AuditStyles.containerBox}>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        sx={AuditStyles.containerGrid}
      >
        <Grid item>
          <Typography
            sx={{
              ...AuditStyles.headingsNameBold700,
              display: "inline-block",
            }}
          >
            Audit Information
          </Typography>
          <Tooltip 
            title="The information in this section are based on the uploaded audit reports (Except for CAP Start Date) . To request updates or report inaccuracy, click the 'Send 4imprint a note' button in the top-right corner."
            placement="right"
          >
            <InfoOutlinedIcon
              sx={{ marginLeft: "8px", color: "#A0A5AD", verticalAlign: "middle" }}
            />
          </Tooltip>
        </Grid>

        <Grid item>
          <Card variant="outlined" style={AuditStyles.editModeCard}>
            <CardContent
              style={{
                padding: "4px",
                paddingLeft: "32px",
                paddingRight: selectedAuditData?.metadata?.originalAuditScore
                  ? "18px"
                  : "32px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography style={AuditStyles.headingsNameBold700}>
                Audit Score
              </Typography>
              <Divider
                orientation="vertical"
                flexItem
                style={{ margin: "16px 16px" }}
              />
              {publishedStatus === false ? (
                <Typography
                  sx={{
                    fontSize: 16,
                    color: "#344054",
                    fontWeight: 500,
                    backgroundColor: "#F2F4F7",
                    padding: "4px 8px",
                    borderRadius: "8px",
                  }}
                >
                  Under Review
                </Typography>
              ) : (
                <Typography style={{
                  ...AuditStyles.bigHeadingFont30,
                  color: selectedAuditData?.risk?.auditScore 
                    ? (selectedAuditData.risk.auditScore >= 81 
                      ? '#027A48' // Green
                      : selectedAuditData.risk.auditScore >= 61 
                        ? '#EAAA08' // Yellow
                        : '#B42318' // Red
                    )
                    : '#101828' // Default color for "-"
                }}>
                  {selectedAuditData?.risk?.auditScore || "-"}
                </Typography>
              )}
              {selectedAuditData?.metadata?.originalAuditScore && (
                <Tooltip
                  title={`The original score on the audit is ${selectedAuditData?.metadata?.originalAuditScore}`}
                >
                  <InfoOutlinedIcon
                    sx={{ marginLeft: "8px", color: "#A0A5AD" }}
                  />
                </Tooltip>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

       {/* Row 1 */}
       <Grid container spacing={2} sx={AuditStyles.containerGrid}>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Audit Start Date</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.facilityData?.audit_start
                ? moment(
                    selectedAuditData.metadata.facilityData.audit_start
                  ).format("MMM. DD, YYYY")
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Audit End Date</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.facilityData?.audit_end
                ? `${moment(
                    selectedAuditData.metadata.facilityData.audit_end
                  ).format("MMM. DD, YYYY")}`
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Auditor Name</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.facilityData?.auditor_name || "-"}
            </Typography>
          </Grid>
        </Grid>

        {/* Row 2 */}
        <Grid container spacing={2} sx={AuditStyles.containerGrid}>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>
              Language (Management)
            </Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.facilityData?.language_management ??
                "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>
              Language (Workers)
            </Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.facilityData?.language_workers ??
                "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Audit Funder</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.auditFunder || "-"}
            </Typography>
          </Grid>
        </Grid>

        {/* Row 3 */}
        <Grid container spacing={2} sx={AuditStyles.containerGrid}>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Audit Scheme</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.facilityData?.auditing_standard ||
                "-"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Auditor Firm</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.facilityData?.auditing_firm || "-"}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography sx={AuditStyles.fieldName}>Address</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.facilityData?.facility_address ??
                "-"}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ margin: "20px 0" }} />

        <Grid container spacing={2} sx={AuditStyles.containerGrid}>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>
              Audit Upload Date
            </Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {moment(selectedAuditData?.createdAt).format("MMM. DD, YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography sx={AuditStyles.fieldName}>
              Publish Date
            </Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.reportReleasedDate
                ? moment(
                    selectedAuditData?.metadata?.reportReleasedDate
                  ).format("MMM. DD, YYYY")
                : ``}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>
              CAP Start Date
            </Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.capStartDate
                ? moment(
                    selectedAuditData?.metadata?.capStartDate
                  ).format("MMM. DD, YYYY")
                : ``}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>
              CAP Completion Date
            </Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {selectedAuditData?.metadata?.capClosureDate
                ? moment(
                    selectedAuditData?.metadata?.capClosureDate
                  ).format("MMM. DD, YYYY")
                : ``}
            </Typography>
          </Grid>
        </Grid>
    </Box>
  );
};

export default AuditInformation;
