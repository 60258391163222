import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  TextField,
  Modal,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { selectFactoryData } from "../../selectors/factory.selector";

import { updateFactoryData } from "../../reducers/factory.reducer";
import { ComponentStyles, FacilityStyles } from "../../styles";
import { selectFactoriesWhichAreInProgressOfCapManagement } from "../../selectors/audit.selector";
import FactoryCAPStatus from "../../pages/factoryCapStatus.component";
import AuditDocumentDrop from "../AuditDetails/auditDocumentDrop.component";
import {
  selectOrganisationId,
  selectSupplierId,
} from "../../selectors/login.selector";
import { getListOfAllAudits } from "../../reducers/audit.reducer";
import { selectNonActiveAuditCount } from "../../selectors/audit.selector";

import moment from "moment";
import { selectFileUploadStatus } from "../../selectors/misc.selector";
const FacilityActionPoints = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [scheduledDate, setScheduledDate] = useState("");
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [step, setStep] = useState(1);
  const [currentEmail, setCurrentEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const refreshAuditList = () => {
    dispatch(
      getListOfAllAudits({
        limit: 99,
        offset: 0,
        supplierId: params.supplierId,
      })
    );
  };
  const organisationId = useSelector(selectOrganisationId);
  const supplierId = useSelector(selectSupplierId);
  const selectedFactory = useSelector(selectFactoryData);
  const factoriesWhichAreInProgressOfCapManagement = useSelector(
    selectFactoriesWhichAreInProgressOfCapManagement
  );

  const publishedStatus = selectedFactory?.latestAudit?.metadata?.reportReleasedByBrand;
  const nonActiveAuditCount = useSelector(selectNonActiveAuditCount);
  const isLatestAuditExist = selectedFactory?.latestAudit ? true : false;


  const currentFactoryNonActiveCount =
    nonActiveAuditCount?.find((item) => item?._id === selectedFactory?._id)
      ?.count || 0;

  const handleSubmit = () => {
    dispatch(
      updateFactoryData({
        supplierId: selectedFactory?.supplierId,
        factoryId: selectedFactory?._id,
        changes: { confirmedNextAuditDate: scheduledDate },
      })
    );
    setSnackbar({
      open: true,
      message: 'Scheduled audit date has been updated successfully',
      severity: 'success'
    });
  };

  const handleOpen = () => {
    setStep(1);
    setCurrentEmail("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUploading(true);
    setTimeout(() => {
      refreshAuditList();
      setUploading(false);
    }, 5000); // Wait for 5 seconds before refreshing the list
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setCurrentEmail(email);

    if (email && !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };
  const fileUploadStatus = useSelector(selectFileUploadStatus);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <>
      <Card style={FacilityStyles.containerCard} sx={{ minHeight: "230px" }}>
        <CardContent>
          <Grid container spacing={3} justifyContent="space-between">
            <Grid item xs={12}>
              <Typography sx={FacilityStyles.headerOfTheCard}>
                Quick Actions
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={3}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                p={2}
                border={1}
                borderColor="grey.300"
                borderRadius={2}
                bgcolor="background.paper"
                minHeight={{ xs: "166px", sm: "166px" }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography sx={FacilityStyles.headerOfTheCard}>
                      Upload Most Recent Audit
                      <Tooltip
                        title="Upload this site's audit reports here once they are received. The submission process has two steps:
                        1. Enter the supplier's point-of-contact email to manage the CAP process.
                        2. Upload all audit files."
                        placement="right"
                      >
                        <InfoOutlinedIcon
                          sx={{
                            marginLeft: "8px",
                            color: "#A0A5AD",
                            verticalAlign: "middle",
                          }}
                        />
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#344054",
                        borderRadius: "8px",
                        padding: "10px 14px",
                        border: "1px solid var(--Gray-3000, #D0D5DD)",
                        background: "var(--Base-White, #FFF)",
                        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                        justifyContent: "center",
                        alignItems: "center",
                        textTransform: "none",
                      }}
                      disabled={fileUploadStatus === 1}
                      onClick={handleOpen}
                      startIcon={<UploadFileIcon sx={{ color: "#667085" }} />}
                    >
                      {fileUploadStatus === 1
                        ? "File is being processed, please wait..."
                        : "Upload Audit"}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        ...ComponentStyles.fieldValue,
                        fontSize: "12px",
                      }}
                    >
                      {currentFactoryNonActiveCount > 0 &&
                        `${currentFactoryNonActiveCount} file${
                          currentFactoryNonActiveCount > 1 ? "s have" : " has"
                        } been uploaded with AI analysis in progress.`}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              {factoriesWhichAreInProgressOfCapManagement[params.factoryId] ? (
                <FactoryCAPStatus status="Ongoing" />
              ) : publishedStatus === false ? (
                <FactoryCAPStatus status="Under Review" />
              ) : isLatestAuditExist === false ? (
                <FactoryCAPStatus status="No previous audit exist" />
              ) : (
                <FactoryCAPStatus status="Completed" />
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                p={2}
                border={1}
                borderColor="grey.300"
                borderRadius={2}
                bgcolor="background.paper"
                minHeight="166px"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontSize: selectedFactory?.metadata
                          ?.confirmedNextAuditDate
                          ? 16
                          : 18,
                        fontWeight: 700,
                        color: "#344054",
                        marginBottom: "10px",
                      }}
                    >
                      Upcoming Scheduled Audit Date
                      <Tooltip
                        title="Use this field to submit the upcoming scheduled audit date after scheduling. Simply select the date and click Submit to update."
                        placement="right"
                      >
                        <InfoOutlinedIcon
                          sx={{
                            marginLeft: "8px",
                            color: "#A0A5AD",
                            verticalAlign: "middle",
                          }}
                        />
                      </Tooltip>
                      <br />
                      {selectedFactory?.metadata?.confirmedNextAuditDate ? (
                        <span style={{ color: "green" }}>
                          {moment(
                            selectedFactory?.metadata?.confirmedNextAuditDate
                          ).format("MMM. DD, YYYY")}{" "}
                          (Last Submitted Date)
                        </span>
                      ) : (
                        ""
                      )}
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} spacing={2} alignItems="center">
                    <Grid item xs={12} sm={7}>
                      <TextField
                        type="date"
                        value={scheduledDate}
                        onChange={(e) => setScheduledDate(e.target.value)}
                        label="Scheduled Date"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={!scheduledDate}
                        fullWidth
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "white",
                          borderRadius: "8px",
                          padding: "10px 14px",
                          border: "1px solid var(--Gray-3000, #D0D5DD)",
                          background: "#3F51B5",
                          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                          justifyContent: "center",
                          alignItems: "center",
                          textTransform: "none",
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Modal open={open} onClose={handleClose}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            p: 4,
            boxShadow:
              "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
            border: "1px solid rgba(234, 236, 240, 1)",
            borderRadius: "12px",
            py: 2,
          }}
        >
          {step === 1 ? (
            <Box sx={{ p: 2, minHeight: "400px" }}>
              <Typography variant="h6" align="center" sx={{ mb: 4 }}>
                Upload Most Recent Audit.
              </Typography>
              <Typography
                sx={{ mb: 4, fontStyle: "italic", fontSize: "14px" }}
                align="center"
              >
                This is a 2 step process.
              </Typography>
              <Typography sx={{ mb: 4 }}>
                <b>Step 1: </b>Enter the contact information for the person that
                will be owning the management of the CAP from the supplier side.
                This is the person the 4imprint team will reach out to when it
                comes to answering/asking questions regarding this audit's CAP.
                All automatic notification e-mails regarding the progress of the
                CAP will also be sent to this CAP contact.
                <br />
                <br />
                <u>The emails should be in the format of "name@domain.com".</u>
              </Typography>
              <Typography sx={{ mb: 2 }}>
                <b>Step 2: </b> Upload the audit report and any related files.
                The files can be in most formats, including but not limited to
                PDF, PNG, and Excels. If you have already worked with a
                third-party audit firm to complete your CAP, feel free to upload
                that file as well.
                <br />
                <br />
                <i style={{ fontSize: "14px" }}>
                  If you press cancel on the next step, the process will be
                  cancelled.
                </i>
              </Typography>
              <TextField
                fullWidth
                value={currentEmail}
                onChange={handleEmailChange}
                placeholder="Enter supplier's CAP contact email address"
                error={emailError}
                helperText={
                  emailError
                    ? "Please enter a valid email address in the format of name@domain.com"
                    : ""
                }
                sx={{ mb: 2 }}
              />
              <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  onClick={() => setStep(2)}
                  disabled={!currentEmail || emailError}
                >
                  Next
                </Button>
              </Box>
            </Box>
          ) : (
            <AuditDocumentDrop
              factoryId={params.factoryId}
              onClose={handleClose}
              mode="process"
              emails={currentEmail}
            />
          )}
        </Card>
      </Modal>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default FacilityActionPoints;
