import React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  CircularProgress,
  Button,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const UploadStatusPopup = ({ open, status, onClose, onCloseStatusPopup }) => {
  const handleClose = () => {
    if (status === 1) {
      if (
        window.confirm(
          "The file is still being processed. Are you sure you want to close this window? You can check the status later in your documents section."
        )
      ) {
        onCloseStatusPopup();
        onClose();
      }
    } else {
      onCloseStatusPopup();
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: "8px",
          padding: "20px",
        },
      }}
    >
      <DialogContent
        style={{
          textAlign: "center",
          padding: "30px",
        }}
      >
        {status === 1 ? (
          <>
            <CircularProgress size={50} style={{ marginBottom: "20px" }} />
            <Typography variant="h6">
              File is being processed, please wait...
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              Depending on the size of the file, this may take a few minutes.
            </Typography>
          </>
        ) : status === 2 ? (
          <>
            <CheckCircleIcon
              style={{
                fontSize: 50,
                color: "#4CAF50",
                marginBottom: "20px",
              }}
            />
            <Typography variant="h6">File uploaded successfully!</Typography>
          </>
        ) : null}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", pb: 2 }}>
        <Button variant="contained" onClick={handleClose}>
          {status === 1 ? "Close (Processing will continue)" : "Close"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadStatusPopup;
