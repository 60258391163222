import React, { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Card,

  Chip,

} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import CustomChipLabel from './customChipLabel.component';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuditIssueCAPData,selectAuditData } from '../../selectors/audit.selector';
import { convertStatusToNormal } from "../../utils/lib";
import { setSelectedCAPManagementIssueId } from "../../reducers/audit.reducer";
import { ComponentStyles } from "../../styles";
import { updateAuditData } from "../../reducers/audit.reducer";

const CapManagementTable = () => {
  const dispatch = useDispatch();
  const auditData = useSelector(selectAuditData);
  const issuesData = useSelector(selectAuditIssueCAPData);

  const handleClick = (id) => {
    dispatch(setSelectedCAPManagementIssueId(id));
  };

  const renderStatusCell = (params) => {
    const status = params.value;
    return (
      <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CustomChipLabel cap={status} capLabel={status} />
      </div>
    );
  };

  const renderCheckmark = (params) => {
    return (
      <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CheckCircleOutlineIcon 
          sx={{ 
            color: params.value ? '#039855' : '#D0D5DD',
            fontSize: '24px'
          }} 
        />
      </div>
    );
  };

  const renderDetailsButton = (params) => {
    return (
      <IconButton
        className="details-button"
        onClick={() => handleClick(params.row.id)}
        sx={{
          backgroundColor: "white",
          padding: 0,
          margin: 0,
          height: "100%",
          width: "100%",
          visibility: 'hidden',
        }}
      >
        <OpenInBrowserIcon className="icon" />
        {/* <Typography variant="caption" sx={{ ml: 1 }}>
          See detail
        </Typography> */}
      </IconButton>
    );
  };

  const columns = [
    {
      field: 'issue',
      headerName: 'Finding',
      minWidth: 200,
      flex: 1.2,
      sortable: false,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: '1.5' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'detailsButton',
      headerName: '',
      minWidth: 30,
      renderCell: renderDetailsButton,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 120,
      flex: 0.5,
      renderCell: (params) => renderStatusCell({ value: convertStatusToNormal(params.value || "Open") }),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'dueInDays',
      headerName: 'Timeline',
      minWidth: 130,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => (
        <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {params.row.status === 'Closed' ? '-' : 
            params.value <= 0 
              ? `Past Due ${-params.value} Days`
              : `Due in ${params.value} Days`
          }
        </div>
      ),
    },
    {
      field: 'supplierFillInCAP',
      headerName: 'Supplier CAP Entry',
      minWidth: 120,
      flex: 0.5,
      renderCell: (params) => renderCheckmark({ value: params.row.capStatus?.isSupplierFillinCAP }),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'brandApproveCAP',
      headerName: '4imprint CAP Entry Approval',
      minWidth: 120,
      flex: 0.5,
      renderCell: (params) => renderCheckmark({ value: params.row.capStatus?.isBrandApproveCAP }),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'supplierProvideCapProof',
      headerName: 'Supplier CAP Proof',
      minWidth: 120,
      flex: 0.5,
      renderCell: (params) => renderCheckmark({ value: params.row.capStatus?.isSupplierProvideCapProof }),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'brandApproveCapProof',
      headerName: '4imprint CAP Proof Approval',
      minWidth: 120,
      flex: 0.5,
      renderCell: (params) => renderCheckmark({ value: params.row.capStatus?.isBrandApproveCAPProof }),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
  ];

  return (
    <Box
      elevation={1}
      sx={{
        borderTop: "0px",
        px: 5,
        my: 10
      }}
    >
      {auditData?.capManagementStatus === "OPEN" ? (
        <>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 2 
          }}>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              CAP Management Process
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 4
            }}
          >
            <Card
              variant="outlined"
              style={{
                ...ComponentStyles.twoLineThreeLineCard,
                width: '100%',
                // maxWidth: '500px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '24px'
              }}
            >
              <Typography variant="body1" color="text.secondary">
                The CAP process has not been started
              </Typography>
            </Card>
          </Box>
        </>
      ) : auditData?.metadata?.reportReleasedByBrand !== true ? (
        <>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 2 
          }}>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              CAP Management Process
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 4
            }}
          >
            <Card
              variant="outlined"
              style={{
                ...ComponentStyles.twoLineThreeLineCard,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '24px'
              }}
            >
              <Typography variant="body1" color="text.secondary">
                The CAP management data is unavailable because the audit has not been published by 4imprint. Contact 4imprint for more details.
              </Typography>
            </Card>
          </Box>
        </>
      ) : (
        <>
        <Box sx={{display: 'flex', alignItems: 'center', gap: 4, mb: 4}}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            CAP Management Process
          </Typography>
          {auditData?.capManagementStatus === "CLOSED" && (
            <Chip
              label="CAP Completed"
              sx={{
                backgroundColor: '#ECFDF3',
                border: '1px solid #027A48',
                borderColor: '#027A48',
                color: '#027A48',
                fontSize: '16px',
                fontWeight: 600,
                padding: '8px 16px',
                height: 'auto',
                '& .MuiChip-label': {
                  padding: '4px'
                }
              }}
            />
          )}
        </Box>
        <Box sx={{ 
          width: '100%', 
          height: 'auto',
          overflow: 'hidden',
          mb: 6
          }}>
          <Box sx={{
            width: '100%',
            overflowX: 'auto'
          }}>
            <DataGrid
              rows={issuesData}
              columns={columns}
              getRowHeight={() => 'auto'}
              hideFooter
              autoHeight
              disableColumnMenu
              disableSelectionOnClick
              disableColumnFilter
              disableColumnSelector
              disableColumnReorder
              disableColumnResize
              disableRowSelectionOnClick
              sortingMode="none"
              sx={{
                border: 'none',
                minWidth: '1400px',
                '& .MuiDataGrid-cell': {
                  borderRight: 'none',
                  borderBottom: '0.5px solid #E0E0E0',
                  padding: '16px',
                  whiteSpace: 'normal',
                  alignItems: 'center',
                },
                '& .MuiDataGrid-main': {
                  width: '100%'
                },
                '& .MuiDataGrid-virtualScroller': {
                  overflow: 'unset'
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#FFFFFF',
                  borderBottom: '0.5px solid #E0E0E0',
                  whiteSpace: 'normal',
                  '& .MuiDataGrid-columnHeader': {
                    borderRight: 'none',
                    cursor: 'default',
                    height: 'unset !important',
                    maxHeight: 'none !important',
                    padding: '16px',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '& .MuiDataGrid-columnSeparator': {
                      display: 'none',
                    },
                    '& .MuiDataGrid-iconButtonContainer': {
                      display: 'none',
                    },
                    '& .MuiDataGrid-sortIcon': {
                      display: 'none',
                    },
                    '& .MuiDataGrid-columnHeaderTitleContainer': {
                      padding: 0,
                      whiteSpace: 'normal',
                      lineHeight: 1.3,
                      justifyContent: 'center',
                      textAlign: 'center',
                    },
                  },
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 600,
                  color: '#344054',
                  whiteSpace: 'normal',
                  lineHeight: 1.3,
                  textAlign: 'center',
                },
                '& .MuiDataGrid-row': {
                  minHeight: '80px !important',
                  maxHeight: 'unset !important',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    '& .details-button': {
                      visibility: 'visible',
                      animation: 'fadeIn 0.2s ease-in',
                    },
                  },
                  '&.Mui-selected': {
                    backgroundColor: 'transparent !important',
                  },
                  '&.Mui-selected:hover': {
                    backgroundColor: 'transparent !important',
                  },
                },
                '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
                  outline: 'none',
                },
                '@keyframes fadeIn': {
                  '0%': {
                    opacity: 0,
                  },
                  '100%': {
                    opacity: 1,
                  },
                },
              }}
            />
          </Box>
        </Box>
        </>
      )}
    </Box>
  );
};

export default CapManagementTable; 
