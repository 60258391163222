import React from "react";
import { Typography, Grid, Tooltip } from "@mui/material";
import FacilityIssuesCard from "./facilityIssuesCard.component";
import TwoLineCard from "../twoLineCard.component";
import { FacilityStyles } from "../../styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import moment from "moment";
const FacilityAuditScheduling = ({ selectedFactory }) => {
    
  const formatDate = (dateStr) => {
    if (dateStr === "N/A") return "N/A";
    return moment(dateStr).format("MM/DD/YY");
  };
  const isInactive = selectedFactory?.metadata?.status === "inactive";
  const auditDueData = {
    nextAuditDueDate: formatDate(selectedFactory?.metadata?.initialPresetDueDate || "N/A"),
    isPastDue: false,
  }
  const nextConfirmedAuditDate = formatDate(selectedFactory?.metadata?.confirmedNextAuditDate || "N/A");
    
  return (
    <>
      <Typography sx={FacilityStyles.mostRecentAuditHeading}>
        Audit Scheduling
        <Tooltip 
          title={
            <React.Fragment>
              • Next Audit Due Date: Set by 4imprint according to the audit scheduling cadence.
              <br />
              <br />
              • Upcoming Scheduled Audit Date: Submitted by the supplier after scheduling the next audit. This can be updated in the Quick Action section on the top of this page.
            </React.Fragment>
          }
          placement="right"
        >
          <InfoOutlinedIcon
            sx={{ marginLeft: "8px", color: "#A0A5AD", verticalAlign: "middle" }}
          />
        </Tooltip>
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <TwoLineCard
            title="Next Audit Due Date"
            nextAuditDate={isInactive ? "N/A" : (auditDueData?.nextAuditDueDate || "N/A")}
            textColor={!isInactive && auditDueData?.isPastDue ? '#B42318' : 'inherit'}
          />
        </Grid>
        <Grid item xs={6}>
          <TwoLineCard
            title="Upcoming Scheduled Audit Date"
            nextAuditDate={nextConfirmedAuditDate}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default FacilityAuditScheduling;