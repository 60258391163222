import { all, put, select, takeLatest, call } from "redux-saga/effects";
import {
  submitLoginRequest,
  loginSuccess,
  loginFailure,
  logout,
  setOrganisationResources,
  getOrganisationResources,
  updateProfileInfo,
  updateProfileInfoSuccess,
  updatePassword,
  updatePasswordForFirstTime,
  updatePasswordForFirstTimeSuccess,
} from "../reducers/login.reducer";
import {
  selectAuthToken,
  selectEmail,
  selectOrganisationId,
  selectOrganisationName,
} from "../selectors/login.selector";
import * as API from "../utils/api";
import axios from "axios";
import { setSupplierData } from "../reducers/supplier.reducer";
import { setNoteData, setNoteModalStatus } from "../reducers/misc.reducer";

function* handleLogin(action) {
  try {
    const email = yield select(selectEmail);
    if (email) {
      const requestURL = API.USER.LOGIN;

      const headers = {
        "Content-Type": "application/json",
      };

      const body = {
        email,
        password: action.payload.password,
      };

      const response = yield axios.post(requestURL, body, { headers });

      if (response && response.status === 200 && response.data) {
        const {
          data: { success, results, message },
        } = response;

        if (success) {
          yield put(loginSuccess(results));
          yield put(setSupplierData(results.supplierData));
          localStorage.setItem("loginState", JSON.stringify(results));
        } else {
          const errorMessage = mapErrorMessage(message);
          yield put(loginFailure(errorMessage));
        }
      }
    }
  } catch (error) {
    if (error.response && error.response.status === 403) {
      yield put(loginFailure("Session expired. Please log in again."));
      yield put(logout()); // Dispatch logout to reset state and storage
    } else if (error.message === "Network Error") {
      yield put(
        loginFailure(
          "Network error. Please check your connection and try again."
        )
      );
    } else {
      yield put(loginFailure(error.toString()));
    }
  }
}

function* handleGetOrganisationResourcesWorker(action) {
  try {
    const authToken = yield select(selectAuthToken);
    const organisationId = yield select(selectOrganisationId);

    const requestURL = API.ORGANISATIONS.GET_RESOURCES.replace(
      "<ORGANISATION_ID>",
      organisationId
    );
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };
    const response = yield axios.get(requestURL, { headers });
    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setOrganisationResources(response.data.results.resources));
      }
    }
  } catch (error) {
    console.error("Failed to fetch organisation resources:", error);
  }
}

function* handleSendNoteWorker(action) {
  try {
    const authToken = yield select(selectAuthToken);
    const organisationId = yield select(selectOrganisationId);
    const organisationName = yield select(selectOrganisationName);

    const requestURL = API.NOTIFICATIONS.SEND_NOTE.replace(
      "<ORGANISATION_ID>",
      organisationId
    );
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };
    const body = { ...action.payload, organisationName, organisationId };
    const response = yield axios.post(requestURL, body, { headers });
    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setNoteModalStatus(false));
      }
    }
  } catch (error) {
    console.error("Failed to send note:", error);
  }
}

function* handleUpdateProfileInfoWorker(action) {
  try {
    const authToken = yield select(selectAuthToken);

    const requestURL = API.USER.UPDATE_PROFILE;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };
    const body = { ...action.payload };
    const response = yield axios.put(requestURL, body, { headers });
    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(updateProfileInfoSuccess(response.data.results));
        alert("Profile updated successfully");
      }
    }
  } catch (error) {
    console.error("Failed to send note:", error);
  }
}

function* handleUpdatePasswordWorker(action) {
  try {
    const authToken = yield select(selectAuthToken);
    const requestURL = API.USER.UPDATE_PASSWORD;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };
    const body = { ...action.payload };
    const response = yield axios.post(requestURL, body, { headers });
    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        alert(
          `Password updated successfully.\n\nYou will be logged out now.\n\nPlease log in again with your new password.`
        );
        yield put(logout());
      }
    }
  } catch (error) {
    console.error("Failed to update password:", error);
  }
}

function* handleUpdatePasswordForFirstTimeWorker(action) {
  try {
    const authToken = yield select(selectAuthToken);
    const requestURL = API.USER.UPDATE_PASSWORD;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };
    const body = { ...action.payload };
    const response = yield axios.put(requestURL, body, { headers });
    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        alert(`Password updated successfully.`);
        yield put(updatePasswordForFirstTimeSuccess());
      }
    }
  } catch (error) {
    console.error("Failed to update password:", error);
  }
}

function mapErrorMessage(message) {
  const errorMapping = {
    "User does not exist.": "Incorrect username or password",
    // Add more mappings as needed
  };
  return errorMapping[message] || "An error occurred. Please try again.";
}

function* watchLoginSaga() {
  yield takeLatest(submitLoginRequest.type, handleLogin);
}

function* watchGetOrganisationResourcesSaga() {
  yield takeLatest(
    getOrganisationResources.type,
    handleGetOrganisationResourcesWorker
  );
}

function* watchSendNoteSaga() {
  yield takeLatest(setNoteData.type, handleSendNoteWorker);
}

function* watchUpdateProfileInfoSaga() {
  yield takeLatest(updateProfileInfo.type, handleUpdateProfileInfoWorker);
}

function* watchUpdatePasswordSaga() {
  yield takeLatest(updatePassword.type, handleUpdatePasswordWorker);
}

function* watchUpdatePasswordForFirstTimeSaga() {
  yield takeLatest(
    updatePasswordForFirstTime.type,
    handleUpdatePasswordForFirstTimeWorker
  );
}

export default function* rootSaga() {
  yield all([
    watchLoginSaga(),
    watchGetOrganisationResourcesSaga(),
    watchSendNoteSaga(),
    watchUpdateProfileInfoSaga(),
    watchUpdatePasswordSaga(),
    watchUpdatePasswordForFirstTimeSaga(),
  ]);
}
