import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { selectNoteModalStatus } from "../selectors/misc.selector";
import { useDispatch, useSelector } from "react-redux";
import { setNoteData, setNoteModalStatus } from "../reducers/misc.reducer";
import {
  selectSupplierId,
  selectSupplierName,
} from "../selectors/login.selector";
import { selectFactoryNameAndSiteId } from "../selectors/factory.selector";
import { useParams } from "react-router-dom";

const SendNote = ({ name, type }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const supplierName = useSelector(selectSupplierName);
  const supplierId = useSelector(selectSupplierId);
  const factoryName = useSelector(selectFactoryNameAndSiteId);

  const auditName = name;

  const isNoteModalOpen = useSelector(selectNoteModalStatus);
  const [noteSubject, setNoteSubject] = React.useState("");
  const [noteText, setNoteText] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  useEffect(() => {
    setNoteSubject("");
    setNoteText("");
  }, [isNoteModalOpen]);

  const onClose = () => {
    dispatch(setNoteModalStatus(false));
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const onSend = () => {
    let dispatchPayload = {
      supplierId: supplierId,
      supplierName: supplierName,
      subject: noteSubject,
      text: noteText,
    };

    if (type === 2) {
      dispatchPayload = {
        ...dispatchPayload,
        factoryId: params.factoryId,
        factoryName: factoryName,
      };
    } else if (type === 3) {
      dispatchPayload = {
        ...dispatchPayload,
        factoryId: params.factoryId,
        factoryName: factoryName,
        auditId: params.auditId,
        auditName: auditName,
      };
    }
    dispatch(setNoteData(dispatchPayload));
    setOpenSnackbar(true);
    onClose();
  };

  return (
    <>
      <Dialog open={isNoteModalOpen} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Send Note to 4imprint</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2, mt: 1 }}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Supplier: {supplierName}
              <br />
              {(type === 2 || type === 3) && `Site: ${factoryName}`}
              <br />
              {type === 3 && `Audit: ${auditName}`}
            </Typography>
          </Box>
          <TextField
            fullWidth
            label="Subject"
            value={noteSubject}
            onChange={(e) => setNoteSubject(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Message"
            multiline
            rows={4}
            value={noteText}
            onChange={(e) => setNoteText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={onSend}
            variant="contained"
            disabled={!noteSubject || !noteText}
          >
            Send Note
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          Note sent successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default SendNote;
