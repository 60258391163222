import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Tooltip,
  Badge,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../reducers/login.reducer";
import { selectSupplierId, selectUserInfo, selectFactoryId } from "../selectors/login.selector";
import { setLeftSideNavigationSelected } from "../reducers/navigation.reducer";
import { selectLeftSideNavigationSelected } from "../selectors/navigation.selector";
import { ComponentStyles } from "../styles";
import LatestUpdateModal from "./latestUpdateModal.component";
import {
  getListOfAuditsInCapManagement,
  setModalStateForManageCAPs,
} from "../reducers/audit.reducer";
import {
  selectModalStateForManageCAPs,
  selectNumberOfInProgressCAPs,
} from "../selectors/audit.selector";
import CapManagementModal from "./capManagementModal.component";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { setSettingsModalStatus } from "../reducers/misc.reducer";
import { selectSettingsModalStatus } from "../selectors/misc.selector";
import Profile from "./profile.component";
import Popover from "@mui/material/Popover";

const LeftSideNavbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const modalStateForManageCAPs = useSelector(selectModalStateForManageCAPs);
  const factoryId = useSelector(selectFactoryId);
  const userInfo = useSelector(selectUserInfo);
  const selected = useSelector(selectLeftSideNavigationSelected);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const handleNavigate = (path) => {
    dispatch(setLeftSideNavigationSelected(path));
    navigate(path);
  };
  useEffect(() => {
    if (params["*"] !== "suppliers") {
      dispatch(setLeftSideNavigationSelected(`/${params["*"]}`));
    }
  }, [params]);

  const [UpdateModalOpen, setUpdateModalOpen] = React.useState(false);
  const settingsModalStatus = useSelector(selectSettingsModalStatus);
  const handleModalOpen = () => {
    setUpdateModalOpen(true);
  };

  const handleModalClose = () => {
    setUpdateModalOpen(false);
  };
  const supplierId = useSelector(selectSupplierId);
  const handleManageCAPsModalOpen = () => {
    dispatch(getListOfAuditsInCapManagement({ supplierId }));
    dispatch(setModalStateForManageCAPs(true));
  };

  const handleSettingsModalOpen = () => {
    dispatch(setSettingsModalStatus(true));
  };

  const numberOfInProgressCAPs = useSelector(selectNumberOfInProgressCAPs);

  const [helpAnchorEl, setHelpAnchorEl] = useState(null);
  
  const handleHelpClick = (event) => {
    setHelpAnchorEl(event.currentTarget);
  };

  const handleHelpClose = () => {
    setHelpAnchorEl(null);
  };

  const helpOpen = Boolean(helpAnchorEl);
  const helpId = helpOpen ? 'help-popover' : undefined;

  return (
    <Box
      sx={{
        maxWidth: 350,
        minWidth: 160,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        padding: 2,
        borderRight: "1px solid #cccccc4a",
        borderBottom: "none",
        borderLeft: "none",
        borderTop: "none",
        backgroundColor: "white",
      }}
    >
      <Box sx={ComponentStyles.logoBox}>
        <img src="/Elm_Logo.svg" alt="Logo" style={ComponentStyles.logoImage} />
      </Box>

      <List sx={{ width: "100%" }}>
        {!factoryId && (
          <ListItemButton
            onClick={() => handleNavigate(`/suppliers`)}
            selected={selected === "/suppliers"}
            sx={{
              bgcolor: selected === "/suppliers" ? "action.selected" : "inherit",
              borderRadius: "12px",
              marginY: 0.5,
            }}
          >
            <ListItemIcon style={{ minWidth: 40 }}>
              <img
                src="/suppliers.svg"
                alt="Suppliers"
                style={ComponentStyles.navbarListItemIcon}
              />
            </ListItemIcon>
            <ListItemText
              primary="Home"
              primaryTypographyProps={{
                sx: ComponentStyles.navbarListItemText,
              }}
            />
          </ListItemButton>
        )}

          <ListItemButton
            onClick={() => handleNavigate(`/factories`)}
            selected={selected === "/factories"}
            sx={{
              bgcolor: selected === "/factories" ? "action.selected" : "inherit",
              borderRadius: "12px",
            }}
          >
            <ListItemIcon style={{ minWidth: 40 }}>
              <img
                src="/factories.svg"
                alt="Factories"
                style={ComponentStyles.navbarListItemIcon}
              />
            </ListItemIcon>
            <ListItemText
              primary="My Sites"
              primaryTypographyProps={{
                sx: ComponentStyles.navbarListItemText,
              }}
            />
          </ListItemButton>
        {/* <ListItemButton
          onClick={() => handleNavigate(`/insights`)}
          selected={selected === "/insights"}
          sx={{
            bgcolor: selected === "/insights" ? "action.selected" : "inherit",
            borderRadius: "12px",
            marginY: 0.5,
          }}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <img
              src="/insights.svg"
              alt="Insights"
              style={ComponentStyles.navbarListItemIcon}
            />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              sx: ComponentStyles.navbarListItemText,
            }}
            primary="Insights"
          />
        </ListItemButton> */}

        <ListItemButton
          onClick={handleManageCAPsModalOpen}
          // selected={selected === "/risk"}
          sx={{
            bgcolor: selected === "/risk" ? "action.selected" : "inherit",
            borderRadius: "12px",
            marginY: 0.5,
          }}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <img
              src="/risk.svg"
              alt="Manage CAPs"
              style={ComponentStyles.navbarListItemIcon}
            />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              sx: ComponentStyles.navbarListItemText,
            }}
            primary={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span>Manage CAPs</span>
                {/* <Badge
                  badgeContent={numberOfInProgressCAPs}
                  color="error"
                  sx={{ marginLeft: 2, mr: 0 }}
                /> */}
              </Box>
            }
          />
        </ListItemButton>
        <ListItemButton
          onClick={() => handleNavigate(`/resources`)}
          selected={selected === "/resources"}
          sx={{
            bgcolor: selected === "/resources" ? "action.selected" : "inherit",
            borderRadius: "12px",
          }}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <img
              src="/resources.svg"
              alt="Resources"
              style={ComponentStyles.navbarListItemIcon}
            />
          </ListItemIcon>
          <ListItemText
            primary="Resources"
            primaryTypographyProps={{
              sx: ComponentStyles.navbarListItemText,
            }}
          />
        </ListItemButton>
      </List>

      <Box sx={{ flexGrow: 1 }} />
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          px: "10px",
          py: 2,
        }}
      >
        <Tooltip title="Latest Updates">
          <IconButton
            onClick={handleModalOpen}
            sx={{
              width: 50, // Make the icon button square
              height: 50,
              padding: 0, // Remove padding to make the icon centered
            }}
          >
            <TipsAndUpdatesOutlinedIcon style={{ color: "#667085" }} />
          </IconButton>
        </Tooltip>
      </Box> */}

      <LatestUpdateModal open={UpdateModalOpen} onClose={handleModalClose} />
      <Box
        sx={{ display: "flex", flexDirection: "column", width: "100%" }}
      >
        <ListItemButton
          onClick={handleHelpClick}
          sx={{
            borderRadius: "12px",
            marginBottom: 1,
          }}
        >
          <HelpOutlineIcon style={{ minWidth: 40 }} />
          <ListItemText
            primary="Help"
            primaryTypographyProps={{
              sx: ComponentStyles.navbarListItemText,
            }}
          />
        </ListItemButton>
        
        <Popover
          id={helpId}
          open={helpOpen}
          anchorEl={helpAnchorEl}
          onClose={handleHelpClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          PaperProps={{
            sx: {
              borderRadius: '12px',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
              border: '1px solid #e0e0e0',
              overflow: 'hidden'
            }
          }}
        >
          <Box sx={{ 
            p: 3, 
            maxWidth: 320,
            backgroundColor: '#fafafa'
          }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: '#333' }}>
              Need Help?
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, lineHeight: 1.6 }}>
              We're here to assist you with any questions about the platform.
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: 1.6 }}>
              For support, please contact us at:
            </Typography>
            <Typography variant="body1" sx={{ 
              fontWeight: 600, 
              // color: '#1976d2',
              mt: 1
            }}>
              assistance@elm-ai.com
            </Typography>
          </Box>
        </Popover>

        <ListItemButton
          onClick={() => handleSettingsModalOpen()}
          sx={{
            bgcolor: selected === "/resources" ? "action.selected" : "inherit",
            borderRadius: "12px",
          }}
        >
          <SettingsOutlinedIcon style={{ minWidth: 40 }} />

          <ListItemText
            primary="Settings"
            primaryTypographyProps={{
              sx: ComponentStyles.navbarListItemText,
            }}
          />
        </ListItemButton>
      </Box>
      <Divider sx={{ width: "100%", marginBottom: 2 }} />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: "10px",
          py: 2,
        }}
      >
        <Box>
          <Typography variant="body2" style={{ fontWeight: 600 }}>
            {userInfo.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {/* {userInfo.email} */}
          </Typography>
        </Box>
        <IconButton
          edge="end"
          aria-label="Logout"
          onClick={() => handleLogout()}
        >
          <img
            src="/logout.svg"
            alt="Logout"
            style={ComponentStyles.navbarListItemIcon}
          />
        </IconButton>
      </Box>
      {modalStateForManageCAPs && <CapManagementModal />}
      {settingsModalStatus && <Profile />}
    </Box>
  );
};

export default LeftSideNavbar;
