import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Divider,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import { selectFactoryIssuesData } from "../../selectors/factory.selector";
import { useSelector } from "react-redux";
import {
  selectAuditIssuesData,
  selectAuditPublishedStatus,
} from "../../selectors/audit.selector";
import { AuditStyles, ComponentStyles } from "../../styles";
const AuditIssuesTable = () => {

  const issuesData = useSelector(selectAuditIssuesData);
  const publishedStatus = useSelector(selectAuditPublishedStatus);


  const [filteredIssues, setFilteredIssues] = useState(
    issuesData.detailedIssues || []
  );
  useEffect(() => {
    setFilteredIssues(issuesData.detailedIssues || []);
  }, [issuesData.detailedIssues]);
  const [isSorted, setIsSorted] = useState(false);
  const handleSort = () => {
    const sortedData = [...filteredIssues].sort((a, b) => {
      if (isSorted) {
        return a.category.localeCompare(b.category);
      } else {
        return b.category.localeCompare(a.category);
      }
    });
    setFilteredIssues(sortedData);
    setIsSorted(!isSorted);
  };

  const handleFilter = (key) => {
    const filteredData = issuesData.detailedIssues.filter(
      (issue) => issue[key] > 0
    );
    setFilteredIssues(filteredData);
  };

  return (
    <Paper elevation={1} sx={AuditStyles.issuesTableContainerPaper}>
      <Box sx={{ height: 300, overflow: "auto" }}>
        {publishedStatus === false ? (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              px: 2,
            }}
          >
            <Typography
              sx={{
                color: "#667085",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              The audit is currently under review by 4imprint. Finding count will be available after the 4imprint review.
            </Typography>
          </Box>
        ) : (
          <Table stickyHeader>
            <TableHead sx={{ zIndex: 1 }}>
              <TableCell
                sx={{
                  ...AuditStyles.issuesTableCell,
                  px: 0,
                }}
              ></TableCell>
              <TableCell sx={AuditStyles.issuesTableCell}>
                <Typography sx={ComponentStyles.headingsName}>Total</Typography>
              </TableCell>
              <TableCell sx={AuditStyles.issuesTableCell}>
                <Typography sx={ComponentStyles.headingsName}>Closed</Typography>
              </TableCell>
              <TableCell sx={AuditStyles.issuesTableCell}>
                <Typography sx={ComponentStyles.headingsName}>Open</Typography>
              </TableCell>
              {issuesData?.issuesSummary?.ci &&
              issuesData?.issuesSummary?.ci > 0 ? (
                <TableCell sx={AuditStyles.issuesTableCell}>
                  <Typography sx={ComponentStyles.headingsName}>CI</Typography>
                </TableCell>
              ) : (
                <></>
              )}
              <TableCell sx={AuditStyles.issuesTableCell}>
                <Typography sx={ComponentStyles.headingsName}>
                  Past Due
                </Typography>
              </TableCell>
            </TableHead>
            <TableRow>
              <TableCell sx={AuditStyles.issuesTableCell}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={ComponentStyles.alignBoxItemsCenter}>
                    <Typography sx={ComponentStyles.headingsName}></Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={AuditStyles.issuesTableCellWithSolidRightBorder}>
                <Box sx={ComponentStyles.alignBoxItemsCenter}>
                  <Typography sx={ComponentStyles.headingsName}>
                    {issuesData.total}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell sx={AuditStyles.issuesTableCellWithDottedRightBorder}>
                <Box sx={ComponentStyles.alignBoxItemsCenter}>
                  <Typography sx={ComponentStyles.headingsName}>
                    {issuesData.issuesSummary.totalClosed}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell sx={AuditStyles.issuesTableCellWithDottedRightBorder}>
                <Box sx={ComponentStyles.alignBoxItemsCenter}>
                  <Typography sx={ComponentStyles.headingsName}>
                    {issuesData.issuesSummary.open}
                  </Typography>
                </Box>
              </TableCell>
              {issuesData?.issuesSummary?.ci &&
              issuesData?.issuesSummary?.ci > 0 ? (
                <TableCell sx={AuditStyles.issuesTableCellWithDottedRightBorder}>
                  <Box sx={ComponentStyles.alignBoxItemsCenter}>
                    <Typography sx={ComponentStyles.headingsName}>
                      {issuesData?.issuesSummary?.ci}
                    </Typography>
                  </Box>
                </TableCell>
              ) : (
                <></>
              )}
              <TableCell sx={AuditStyles.issuesTableCell}>
                <Box sx={ComponentStyles.alignBoxItemsCenter}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 15,
                      color: "#344054",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {issuesData.issuesSummary.pastDue}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>

            <TableBody>
              {filteredIssues.map((issue, index) => (
                <TableRow key={index} sx={{ cursor: "default" }}>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: issue.color,
                      maxWidth: "150px",
                      pr: 0,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      cursor: "default",
                      borderBottom: "none",
                      py: 1.5,
                    }}
                  >
                    {issue.category.length > 15 ? (
                      <Tooltip
                        title={issue.category}
                        placement="top"
                        sx={{ width: "32px", color: issue.color }}
                      >
                        <span>{issue.category}</span>
                      </Tooltip>
                    ) : (
                      issue.category
                    )}
                  </TableCell>

                  <TableCell
                    sx={{
                      ...AuditStyles.issuesTableCellWithSolidRightBorder,
                      borderBottom: "none",
                    }}
                  >
                    <Box sx={ComponentStyles.alignBoxItemsCenter}>
                      <Typography sx={ComponentStyles.headingsName}>
                        {issue.open + issue.totalClosed || "0"}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...AuditStyles.issuesTableCellWithDottedRightBorder,
                      borderBottom: "none",
                    }}
                  >
                    <Box sx={ComponentStyles.alignBoxItemsCenter}>
                      <Typography sx={ComponentStyles.headingsName}>
                        {issue.totalClosed || "0"}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...AuditStyles.issuesTableCellWithDottedRightBorder,
                      borderBottom: "none",
                    }}
                  >
                    <Box sx={ComponentStyles.alignBoxItemsCenter}>
                      <Typography sx={ComponentStyles.headingsName}>
                        {issue.open || "0"}
                      </Typography>
                    </Box>
                  </TableCell>
                  {issuesData?.issuesSummary?.ci &&
                  issuesData?.issuesSummary?.ci > 0 ? (
                    <TableCell
                      sx={{
                        ...AuditStyles.issuesTableCellWithDottedRightBorder,
                        borderBottom: "none",
                      }}
                    >
                      <Box sx={ComponentStyles.alignBoxItemsCenter}>
                        <Typography sx={ComponentStyles.headingsName}>
                          {issue.ci || "0"}
                        </Typography>
                      </Box>
                    </TableCell>
                  ) : (
                    <></>
                  )}
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      borderBottom: "none",
                      py: 1.5,
                    }}
                  >
                    <Box sx={ComponentStyles.alignBoxItemsCenter}>
                      <Typography sx={ComponentStyles.headingsName}>
                        {issue.pastDue || "0"}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
    </Paper>
  );
};

export default AuditIssuesTable;
