import React, { useEffect, useState } from "react";

import { getListOfAllAudits } from "../../reducers/audit.reducer";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuditList,
  selectAuditListForFactory,
  selectNonActiveAuditCount,
} from "../../selectors/audit.selector";
import { selectFactoryData } from "../../selectors/factory.selector";
import {
  Box,
  Typography,
  Button,
  Modal,
  Card,
  CircularProgress,
  TextField,
  Stack,
  Chip,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AuditList from "../auditList.component";
import FactoryAuditList from "./factoryAuditHistoryListCard.component";
import FactoryAuditListCard from "./factoryAuditHistoryListCard.component";
import AuditDocumentDrop from "../AuditDetails/auditDocumentDrop.component";
import { selectEmail, selectSupplierId } from "../../selectors/login.selector";
import { checkInternalEmail } from "../../utils/lib";

const FacilityAuditList = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [step, setStep] = useState(1);
  const [currentEmail, setCurrentEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const supplierId = useSelector(selectSupplierId);
  const userEmail = useSelector(selectEmail);
  const isInternalUser = checkInternalEmail(userEmail);

  const refreshAuditList = () => {
    dispatch(
      getListOfAllAudits({
        limit: 99,
        offset: 0,
        supplierId: supplierId,
      })
    );
  };

  useEffect(() => {
    if (selectAudits && selectAuditList.length === 0) {
      refreshAuditList();
    }
  }, [supplierId]);

  const selectAudits = useSelector((state) =>
    selectAuditListForFactory(state, params.factoryId)
  ).filter((audit) => audit.state === "active");

  const handleOpen = () => {
    setStep(1);
    setCurrentEmail("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUploading(true);
    setTimeout(() => {
      refreshAuditList();
      setUploading(false);
    }, 5000); // Wait for 5 seconds before refreshing the list
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setCurrentEmail(email);

    if (email && !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const factoryData = useSelector(selectFactoryData);

  const nonActiveAuditCount = useSelector(selectNonActiveAuditCount);

  const currentFactoryNonActiveCount =
    nonActiveAuditCount?.find((item) => item?._id === factoryData?._id)?.count ||
    0;
    
  return (
    <div style={{ position: "relative", paddingBottom: "70px" }}>
      {uploading && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            position: "absolute",
            top: 10,
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            borderRadius: "8px",
            boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
            zIndex: 1,
          }}
        >
          <CircularProgress size={20} sx={{ marginRight: "8px" }} />
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 14,
              color: "#8B8D97",
            }}
          >
            Uploading in progress
          </Typography>
        </Box>
      )}
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: 2,
        }}
      >
        <Button
          sx={{
            mt: 2,
            fontSize: "14px",
            fontWeight: 600,
            color: "#344054",
            borderRadius: "8px",
            padding: "10px 14px",
            border: "1px solid var(--Gray-3000, #D0D5DD)",
            background: "var(--Base-White, #FFF)",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "none",
          }}
          onClick={handleOpen}
          startIcon={<UploadFileIcon sx={{ color: "#667085" }} />}
          disabled={!isInternalUser}
        >
          Upload Audit
        </Button>
        <Typography variant="subtitle2">
          Disabled during Data Migration and Cleaning
        </Typography>
      </Box> */}

      {currentFactoryNonActiveCount > 0 && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 16,
              color: "#8B8D97",
              textAlign: "center",
            }}
          >
            {currentFactoryNonActiveCount} file
            {currentFactoryNonActiveCount > 1 ? "s" : ""} have been successfully
            uploaded, and the AI analysis is in progress.
          </Typography>
        </Box>
      )}

      {selectAudits.length === 0 && currentFactoryNonActiveCount === 0 ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "50px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 16,
              color: "#8B8D97",
            }}
          >
            No audit received yet
          </Typography>
        </Box>
      ) : (
        <FactoryAuditListCard selectAudits={selectAudits} />
      )}

      <Modal open={open} onClose={handleClose}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            p: 4,
            boxShadow:
              "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
            border: "1px solid rgba(234, 236, 240, 1)",
            borderRadius: "12px",
            py: 2,
          }}
        >
          {step === 1 ? (
            <Box sx={{ p: 2, minHeight: "400px" }}>
              <Typography variant="h6" align="center">
                Here is an explanation of the audit upload process.
              </Typography>
              <Typography
                sx={{ mb: 4, fontStyle: "italic", fontSize: "14px" }}
                align="center"
              >
                This is a 2 step process.
              </Typography>
              <Typography sx={{ mb: 4 }}>
                <b>Step 1: </b>Enter the email addresses to notify and click
                next.
                <br />
                <i style={{ fontSize: "14px" }}>
                  These emails will be used for communications from the system
                  and respective people involved.
                </i>
                <br />
                <br />
                <u>The emails should be in the format of "name@domain.com".</u>
              </Typography>
              <Typography sx={{ mb: 2 }}>
                <b>Step 2: </b>Upload the audit report related files.
                <br />
                <i style={{ fontSize: "14px" }}>
                  If you press cancel on the next step, the process will be
                  cancelled.
                </i>
              </Typography>
              <TextField
                fullWidth
                value={currentEmail}
                onChange={handleEmailChange}
                placeholder="Enter email address"
                error={emailError}
                helperText={
                  emailError
                    ? "Please enter a valid email address in the format of name@domain.com"
                    : ""
                }
                sx={{ mb: 2 }}
              />
              <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  onClick={() => setStep(2)}
                  disabled={!currentEmail || emailError}
                >
                  Next
                </Button>
              </Box>
            </Box>
          ) : (
            <AuditDocumentDrop
              factoryId={params.factoryId}
              onClose={handleClose}
              mode="process"
              emails={currentEmail}
            />
          )}
        </Card>
      </Modal>
    </div>
  );
};

export default FacilityAuditList;
